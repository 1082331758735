
export const MenuBarData = [
    {
        title: 'Home',
        path: '/home',
        cName: 'nav-text'
    },
    {
        title: 'Mindscope DynamicRouting Session Overview',
        path: '/mindscope_dynamic_routing_session',
        cName: 'nav-text'
    },
    {
        title: 'Mindscope DynamicRouting Workflow Overview',
        path: '/mindscope_dynamic_routing_workflow',
        cName: 'nav-text'
    },
]